import React, { FC, useEffect, useState } from 'react';
import './styles.scss';

interface IAppProps {
  percent?: number;
  text?: string;
  noMessage?: boolean;
}

const CustomProgress: FC<IAppProps> = ({ percent, text, noMessage }) => {
  const [count, setCount] = useState(0);
  //@ts-ignore
  var interval = null;
  useEffect(() => {
    interval = setInterval(function () {
      setCount(count => {
        if (count + 2 >= 96) {
          //@ts-ignore
          clearInterval(interval);
        }
        return count + 2;
      });
    }, 100);

    return () => {
      //@ts-ignore
      clearInterval(interval);
    };
  }, []);
  return (
    <section className="loader-progres section">
      <div className={`final__animation-bar-2 ${noMessage ? '' : 'show-tooltip'}`}>
        <span data-label={text || percent + '%'} style={{ width: percent || count + '%' }}></span>
      </div>
    </section>
  );
};

export default CustomProgress;
