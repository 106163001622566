import React from 'react';
import { navigate } from '@reach/router';
import './styles.scss';

const ToggleButton: React.FC<any> = ({ setAgency, agency, promo }) => {
  const handleClickRegular = () => {
    promo ? navigate('/pricing_halloween') : setAgency(false);
  };
  const handleClickAgancy = () => {
    promo ? navigate('/pricing_agency_halloween') : setAgency(true);
  };
  return (
    <div className="toggle-button-component">
      <button
        className={`button ${!agency ? 'active' : ''}`}
        onClick={handleClickRegular}
      >
        Regular
      </button>
      <button
        className={`button ${agency ? 'active' : ''}`}
        onClick={handleClickAgancy}
      >
        Agency
      </button>
    </div>
  );
};

export default ToggleButton;
