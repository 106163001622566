import React, {FC, useEffect, useState} from 'react';
import './styles.scss';

const Timer: FC<any> = (): JSX.Element => {
  // timer
  const calculateTimeLeft = () => {
    const difference = +new Date('Dec 26 2022 07:00:00 GMT') - +new Date(new Date().toUTCString());
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });
  //timer

  return (
    //@ts-ignore
    timeLeft.days || timeLeft.hours || timeLeft.minutes || timeLeft.seconds ? (
      <div className="countdown_container-main">
        {
          //@ts-ignore
          timeLeft.days ? (
            <div className="unit">
              <div className="number_container">
                {
                  //@ts-ignore
                  timeLeft.days
                }
              </div>
              <div className="label_container">Days</div>
            </div>
          ) : null
        }
        <div className="unit">
          <div className="number_container">
            {
              //@ts-ignore
              timeLeft.hours
            }
          </div>
          <div className="label_container">Hours</div>
        </div>
        <div className="unit">
          <div className="number_container">
            {
              //@ts-ignore
              timeLeft.minutes
            }
          </div>
          <div className="label_container">Minutes</div>
        </div>
        <div className="unit">
          <div className="number_container">
            {
              //@ts-ignore
              timeLeft.seconds
            }
          </div>
          <div className="label_container">Seconds</div>
        </div>
      </div>
    ) : (
      <></>
    )
  );
};

export default Timer;
