import React from 'react';
// import {ToggleButtonPropsType} from './types';
import './styles.scss';

const renderRadioButtonField: React.FC<any> = (props: any) => {
  return (
    <div className="toggle-btn dashboard">
      <input
        className="toggle-btn__input"
        type="checkbox"
        name="checkbox"
        onChange={props.onChange}
        checked={props.checked}
      />
      <span className={`dashboard__sales-toggle-left text-center ${!props.checked ? 'green' : ''}`} style={{fontSize: props.hexowatch ? '16px' : ''}}>Monthly</span>
      <button type="button" className="toggle-btn__input-label" onClick={props.onChange}>
        Toggle
      </button>
      <span className={`dashboard__sales-toggle-right text-center ${props.checked ? 'green' : ''}`} style={{fontSize: props.hexowatch ? '16px' : ''}}>Annually <span ></span></span>
    </div>
  );
};

export default renderRadioButtonField;
